import React, { useEffect, useState } from 'react';

const FacebookLoginButton = () => {
  const [sdkLoaded, setSdkLoaded] = useState(false);
  console.log("page loaded");

  useEffect(() => {
    // Initialize the Facebook SDK
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '493407539733380', // Replace with your Facebook App ID
        cookie: true,
        xfbml: true,
        version: 'v21.0',
      });
      setSdkLoaded(true); // SDK has been initialized
    };

    // Load the Facebook SDK script
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }, []);

  const handleLogin = () => {
    if (!sdkLoaded) {
      console.error('Facebook SDK not loaded yet.');
      return;
    }

    window.FB.login(
      (response) => {
        if (response.authResponse) {
          console.log('Login successful:', response);
          // Process login response as needed
        } else {
          console.error('User cancelled login or did not fully authorize.');
        }
      },
      {
        scope: 'public_profile,email,whatsapp_business_messaging,whatsapp_business_management',
      }
    );
  };

  return (
    <div className="facebook-login-container">
      <button className="facebook-login-button" onClick={handleLogin}>
        <i className="fab fa-facebook"></i> Login with Facebook
      </button>

      {/* Inline styles for simplicity */}
      <style jsx>{`
        .facebook-login-container {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
          background-color: #f4f4f9;
        }

        .facebook-login-button {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #4267b2;
          color: white;
          font-size: 16px;
          font-weight: bold;
          border: none;
          padding: 10px 20px;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        .facebook-login-button:hover {
          background-color: #365899;
        }

        .facebook-login-button i {
          margin-right: 8px;
          font-size: 18px;
        }
      `}</style>
    </div>
  );
};

export default FacebookLoginButton;
